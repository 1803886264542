.corp_divider {
  position: relative;
  margin-right: 8px;
  padding-right: 8px;

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    content: '';
    display: inline-block;
    width: 1px;
    height: 8px;
    background-color: #262626;
  }
  &:last-of-type {
    &::after {
      display: none;
    }
  }
}

@media (max-width: 1023px) {
  .corp_divider {
    margin-right: 4px;
    padding-right: 4px;
    &::after {
      height: 8px;
    }
  }
}
