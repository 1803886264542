.link_hover {
  &:hover {
    > span {
      &:nth-of-type(1) {
        text-decoration: underline;
      }
    }
  }
}

.dropdownMobileWrap {
  min-width: 320px;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: stretch;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
